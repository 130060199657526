<template>
  <div class="content flex-box vertical">
    <div class="scroll-area flex-grow">
      <a-descriptions title="日志信息" :column="2" bordered class="mb-24">
        <a-descriptions-item label="服务名称">
          {{ detail.serviceName }}
        </a-descriptions-item>
        <a-descriptions-item label="服务日期">
          {{ detail.serviceDate }}
        </a-descriptions-item>
        <a-descriptions-item label="组长">
          <open-data
            type="userName"
            v-if="detail.volunteerService && detail.volunteerService.group_leader"
            :openid="detail.volunteerService.group_leader"
          />
        </a-descriptions-item>
        <a-descriptions-item label="上传状态">
          {{ detail.statusText }}
        </a-descriptions-item>
      </a-descriptions>
      <a-descriptions title="日志内容" :column="1" class="mb-24">
        <a-descriptions-item>
          {{ detail.content }}
        </a-descriptions-item>
      </a-descriptions>
      <a-descriptions title="环境照片" :column="1">
        <a-descriptions-item>
          <div class="detail-info-value" v-if="detail.img">
            <div class="file-list" v-if="detail.img">
              <img
                class="file-item"
                :src="f"
                v-for="(f, i) in detail.img.split(',')"
                :key="i"
                @click="previewImg(f, detail)"
              />
            </div>
            <div v-else>无</div>
          </div>
        </a-descriptions-item>
      </a-descriptions>
    </div>
    <div class="footer">
      <a-button @click="back">返回</a-button>
    </div>
  </div>
</template>

<script>
import { uploadStatusOptions } from "../../../common/hr/volunteer";
import { getKeyTitle, timestampConvertString } from "../../../common/js/tool";

export default {
  name: "TeamLeaderLogDetail",
  data() {
    return {
      detail: {},
      visible: false,
    };
  },
  computed: {
    id() {
      return this.$route.params.lid;
    },
  },
  created() {
    this.getDetail();
  },
  methods: {
    getDetail() {
      if (this.id) {
        this.$axios({
          url: `/admin/volunteer-log/${this.id}`,
          method: "GET",
          params: {
            expand: "volunteerGroup.groupLeader,volunteerService",
          },
        }).then((res) => {
          res.serviceName = res.volunteerService?.name || "-";
          res.serviceDate = timestampConvertString(res.volunteerService?.service_date) || "-";
          res.statusText = getKeyTitle(uploadStatusOptions, res.status, "value", "label");
          this.detail = res;
        });
      }
    },
    back() {
      this.$router.back();
    },
    previewImg(img, item) {
      let value = this.detail.img;
      if (typeof value === "string") {
        value = value.split(",").map((v) => {
          return {
            url: v,
            title: item.label,
          };
        });
      }
      this.$store.dispatch("showImgList", value, img);
    },
  },
};
</script>

<style lang="less" scoped>
.footer {
  text-align: center;
}
.content {
  padding-bottom: 0;
}
.mb-24 {
  margin-bottom: 24px;
}
</style>
